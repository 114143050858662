import { 
    ADD_MESSAGE,
    GET_MESSAGES
} from '../constants'

const INITIAL_STATE = {
    messages: [],
    isGetted: false
}

const chat = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_MESSAGES: {
            return { ...state, messages: action.payload, isGetted: true }
        }
        case ADD_MESSAGE: {
            return { ...state, messages: [ action.payload, ...state.messages ] }
        }
        default:
            return state
    }
}

export default chat