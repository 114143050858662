import React from 'react'
import qs from 'qs'
import './App.css'

function formatGooglePhoto(url) {
  return `https://drive.google.com/uc?export=view&id=${url.split("file/d/")[1].split("/view")[0]}`
}

class Pdf extends React.Component {
    state = {
        tab: "",
        activeVideo: 0,
        activeGallery: Number(qs.parse(window.location.search, { ignoreQueryPrefix: true }).gallery) || 0,
        activePhoto: Number(qs.parse(window.location.search, { ignoreQueryPrefix: true }).photo) || 0,
        isAuth: false
    }

    getAlbum() {
      return [
        { 
          title: "V1 - Formațiuni renale rare – chist hidatic renal. Prezentare de caz", 
          url: "https://drive.google.com/file/d/1e7mieUh5aqXAyOOmOjVGBvX4fxzQrTrn/preview"
        },
        {
          title: "V5 - RECONSTRUCȚIA URETREI PENIENE ÎNTR-UN SINGU…_ LA UN PACIENT CU HIPOSPADIAS MULTIPLU OPERAT",
          url: "https://drive.google.com/file/d/1gHcjLtTlE1YTjVUf_uXvc-ag1kKe3m84/preview"
        },
        {
          title: "V7 - Tratamentul chirurgical al limfedemului peno-scrotal post injectare kanamicina.",
          url: "https://drive.google.com/file/d/1gIL6TlKTXLbZCRKRsg3dMdTYBPcuOknP/preview"
        },
        {
          title: "V9 - RECONSTRUCTIA URETREI PENIENE IN DOUA ETAPE …U HIPOSPADIAS MULTIPLU OPERAT SI CORDAJ PENIAN",
          url: "https://drive.google.com/file/d/1gGlSzBUO8DyMQIjY_uc4nrKv2hZ2QGV3/preview"
        },
        {
          title: "V10 - Stentul ureteral Allium o nouă metodă de tr…tament a stenozelor tractului urinar superior",
          url: "https://drive.google.com/file/d/1gIH_rggvKfwD1hU8mUfUJZkua_e85Tw9/preview"
        },
        { 
          title: "V16 - Dezvoltarea unui algoritm bazat pe inteligen…inile de rezonanță magnetică multiparametrică", 
          url: "https://drive.google.com/file/d/1e7ryJ7DxZrgFSP4MDrqGeRr93BMB-5yB/preview"
        },
        { 
          title: "V17 - Biopsia prostatică transrectală de fuziune IRM-US abordul leziunilor localizate anterior", 
          url: "https://drive.google.com/file/d/1e7tm9h3x0RZbQuP1IO3PA1JtNqOgyfPb/preview"
        },
        { 
          title: "V18 - Rolul ICG în evaluarea perfuziei ureterale i…erivație urinară intracorporeală de tip Briker", 
          url: "https://drive.google.com/file/d/1e8EsNmJC0WLG6kFvPVfJo451H9FnzuuA/preview"
        },
        { 
          title: "V19 - Îmbunătățirea tehnicii chirurgicale și a rez…riența unui singur chirurg în context national", 
          url: "https://drive.google.com/file/d/1e8S99g-PFaA8NHoLNYvezDCuOFr_jaqr/preview"
        },
        { 
          title: "V20 - Experiența unui singur chirurg în context na…nală mare și tendințe în nefrectomia parțială", 
          url: "https://drive.google.com/file/d/1eGrn-LTxzkC8NWCwJiYj2JQEOxTqf7za/preview"
        },
        { 
          title: "V22 - Reimplantare ureterală stângă asistată robotic pentru endometrioză infiltrativă profundă", 
          url: "https://drive.google.com/file/d/1eK-CriBySVOl3niIZ6Cny4Cqarg0PMUC/preview"
        },
        { 
          title: "V23 - Limfadenectomie retroperitoneală interaortocavă prin abord laparoscopic 3D", 
          url: "https://drive.google.com/file/d/1eKC7sbfIpHrEDM-Dkj3iXC09436wUu5_/preview"
        },
        { 
          title: "V25 - Suprarenalectomie transperitoneală laparosco…icil de feocromocitom asociat cu sindrom Sweet", 
          url: "https://drive.google.com/file/d/1eKqJvq86l4v5zzDwXe9IesHmIYBTpRaj/preview"
        },
        { 
          title: "V26 - Nefrectomia parțială transperitoneală laparo… pentru tumori renale complexe tips and tricks", 
          url: "https://drive.google.com/file/d/1eKxJXtKOsyfCDmAvKlUgdbV95gIKfM9M/preview"
        },
        { 
          title: "V27 - Ureteropielolitotomie transperitoneală laparoscopică 3D abordarea noastră", 
          url: "https://drive.google.com/file/d/1eLdAxH5f2IeG5e6UdjanqKKXeqpLCp73/preview"
        },
        {
          title: "V28 - Anastomoza uretro-vezicală laparoscopică fo…ngur ac versus fir cu două ace tehnica noastră",
          url: "https://drive.google.com/file/d/1gSww14rwHsydouHnZmXAid7Qm_pigx3y/preview"
        },
        { 
          title: "V29 - Chirurgia nephron-sparing la un pacient cu R…la nivelul rinichiului stâng abordarea noastră", 
          url: "https://drive.google.com/file/d/1eMcfkQAUd1_qbWhVqH7NcfRdu1F5moZd/preview"
        },
        {
          title: "V30 - Cistectomia radicală laparoscopică 3D cu ur…erostomie bilaterală la bărbat tehnica noastră",
          url: "https://drive.google.com/file/d/1gTgxcpYwDFWJ34364-_vpKoP8-8H7KCi/preview"
        },
        {
          title: "V31 - Cura laparoscopică 3D a fistulei vezico-vag…le după histerectomie totală abordarea noastră",
          url: "https://drive.google.com/file/d/1gTU6d2PRMbdfKTVtlKi3FfAtLfwACXHE/preview"
        },
        {
          title: "V32 - Cistoprostatectomia radicală laparoscopică …onduct ileal tip Bricker total intracorporeal",
          url: "https://drive.google.com/file/d/1gb47m4E6_yL9hSViMaL4iT77G-H7tdph/preview"
        },
        { 
          title: "V33 - Prostatectomia radicală laparoscopică 3D în …ologie a Spitalului Județean de Urgență Zalău", 
          url: "https://drive.google.com/file/d/1eMr_BwZFijljgOWZejN4ShURXKMMPUKW/preview"
        },
        { 
          title: "V34 - Nefrectomie radicală laparoscopică 3D pentru…enale și determinare secundară hepatică unică ", 
          url: "https://drive.google.com/file/d/1eNFyw7CCfdc3j7mgi7oftuoYHAkEE9k7/preview"
        },
        { 
          title: "V35 - Nefrectomie parțială laparoscopică 3D pentru…ră renală în contact direct cu pediculul renal", 
          url: "https://drive.google.com/file/d/1eNH8f_WfIHjf4OQifo1FDyV7KPmcxkUx/preview"
        },
        { 
          title: "V36 - Prezentare de caz enucleorezecție laparoscop… pentru doua formațiuni tumorale renale drepte", 
          url: "https://drive.google.com/file/d/1eNK2OUYUy8KiX9UFkvTm8YheMG4dNAfW/preview"
        },
        { 
          title: "V38 - Abordul laparoscopic 3D al cancerului de pro…3 trocare datorită antecedentelor chirurgicale", 
          url: "https://drive.google.com/file/d/1enTHjqRu5Ut6dCCoVvys-HqcATGwy1YC/preview"
        },
        { 
          title: "V39 - Limfadenectomia radicală laparoscopică 3D inghinală și pelvină pentru tumorile peniene", 
          url: "https://drive.google.com/file/d/1es5Ee-LqjgXSPGbPvg_KXJyalUHrqCq5/preview"
        },
        { 
          title: "V40 - Prostatectomia radicală laparoscopică 3D în …atelor chirurgicale, minimizarea invazivității", 
          url: "https://drive.google.com/file/d/1es91yTBr_d4dkLiP3OqfsLzVAzKo7cYT/preview"
        },
        { 
          title: "V41 - Nefrectomia parțială laparoscopică 3D pentru tumori renale bilaterale, multifocale", 
          url: "https://drive.google.com/file/d/1esMupxW1vmvqGjLqMLVcvNqbQR2r81Gt/preview"
        },
        { 
          title: "V42 - Nefrectomie radicală stângă laparoscopică tr…gigant o provocare în chirurgia laparoscopică", 
          url: "https://drive.google.com/file/d/1esUFwHSU-HjKQ3ISgScrBJGLcqo6TGDa/preview"
        },
        { 
          title: "V43 - Adenomectomia transvezicală prin abord lapar…n tratamentul hiperplaziei benigne de prostată", 
          url: "https://drive.google.com/file/d/1eseg-9ppkmM6S4gimu9RDN4Y-7D1TCsu/preview"
        },
        { 
          title: "V44 - Tumori renale maligne sincrone la nivelul rinichiului drept", 
          url: "https://drive.google.com/file/d/1esf-xs7czuty5unOzj2FDvfeKoyz87qE/preview"
        },
        { 
          title: "V46 - Sacrocolpopexia cu plasă asistată robotic și…tul defectelor de compartiment anterior pelvin", 
          url: "https://drive.google.com/file/d/1esiYpj1rLEfaUcfwpvjPsMNoVGBqTLfv/preview"
        },
        { 
          title: "V47 - Nefrectomie parțială asistată robotic pentru tumora stadiu T2 pe rinichi unic", 
          url: "https://drive.google.com/file/d/1eszIb6iL627xGS5pVxRzTy5-0IyA3VuK/preview"
        },
        { 
          title: "V48 - Cura chirurgicală asistată robotic a fistule…rigonale cu interpunerere de lambou peritoneal", 
          url: "https://drive.google.com/file/d/1etDFHacGMHwiSDlfLYk6E0lNTtBvFzxv/preview"
        },
        { 
          title: "V49 - Adenomectomie transvezicală și cistolitotomi…ignă de prostată și litiază vezicală secundară", 
          url: "https://drive.google.com/file/d/1etDLsWjz99zENDB_cPzbwAbDhxSlbb2J/preview"
        },
        { 
          title: "V50 - Cura fistulei vezico-vaginale prin abord laparoscopic 3D", 
          url: "https://drive.google.com/file/d/1gZUaqJ2lx5HOFgn2lpZQiCikgyUjXXKz/preview"
        },
      ]
    }
    getGallery() {
        let galleries = [
            {
              label: 'Sesiune postere 1 - Oncologie 1', 
              photos: [
                {
                  title: 'PM1_1 - 5 ani de experiență în puncția prostatică cu fuziune ecografie-MRI (F. Tănase)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fDMAX2CC-WFsjBu4Av8a-dAuBIXGgcV6/view?usp=share_link')
                },
                {
                  title: 'PM1_2 - Biopsia de prostată IRM-TRUS Fusion, rezultate din serviciul nostru după patru ani de practică (L. Giurgiu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fYsDF7lEydSqdLz_Yu-YhQWYL6m9pC5_/view?usp=share_link')
                },
                {
                  title: 'PM1_3 - Biopsia prostatică transperineală ghidată prin fuziune cognitivă cu Rezonanța Magnetică Nucleară (M. A. Radu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gFuUmFZmFYWUMwsCK5Wdo5LZcW8RMOwO/view?usp=sharing')
                },
                {
                  title: 'PM1_4 - Tehnologia emergentă în puncția prostatică ghidată RMN – progrese în precizie și eficiență comparativ cu biopsia TRUS (A. Griga)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fPBjt354GyqW-zpgveWIsCdKuIaj1tJW/view?usp=sharing')
                },
                {
                  title: 'PM1_5 - Segmentarea automată a tumorilor vezicii urinare pe imaginile de rezonanță magnetică, folosind algoritmi bazați pe inteligența artificială, de tip rețele neuronale (T. Telecan) ', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fEhXrKND-GGvPDSlHeAkJGeUvKDiwdsZ/view?usp=sharing')
                },
                {
                  title: 'PM1_6 - Eficacitatea chimioterapiei și a tratamentului hormonal de nouă generație în tratamentul cancerului de prostată metastatic (I. Dițescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fK521sZ1ZM2m-fZItnrMxMM7qEzD7htN/view?usp=sharing')
                },
                {
                  title: 'PM1_7 - Limfodisecția de salvare în cadrul tratamentului multimodal al adenocarcinomului prostatic – prezentare de caz (A. Andreșanu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gX8m0KCdqnc914cogTuKmTmHMZbnRs6K/view?usp=sharing')
                },
                {
                  title: 'PM1_8 - Radioterapia și complicațiile oncologice în cancerul de prostată (T. Teodor)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gRUGfI3_kxbRuMFKhNerM2Ps3qGzVOlw/view?usp=sharing')
                },
                {
                  title: 'PM1_9 - Implementarea nomogramei preoperatorii în anticiparea marginilor pozitive după prostatectomia radical (L.T. Reman)',
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fUs_2aN7bOO9lsVful4FmmexGzE_Z-ZB/view?usp=sharing')
                },
                {
                  title: 'PM1_10 - Aspecte clinice și patologice ale tumorilor vezicale maligne la pacienții tineri (A. Croitor)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gFeQ6jcMLTOQauQjEAlNifl_xpUPKZeX/view?usp=sharing')
                },
                {
                  title: 'PM1_11 - Agregarea tumorilor uroteliale între pacienți înrudiți (Gluck G.)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fOHp1VF0ZJM0Spat3r0wGpvdDNkLhH61/view?usp=sharing')
                },
                {
                  title: 'PM1_12 - Carcinom urotelial la pacient transplantat renal (L. Discălicău)1,2', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fSxwFT3wk0rr00cblxt-3hVPvgF_nEDu/view?usp=sharing')
                },
                // {
                //   title: 'PM1_13 - Utilizarea retroperitoneoscopiei în terapia carcinomului renal. Nefrectomia radicală; parțială; abord bipolar pentru tumori cu tromb în vena cavă. (B. Feciche)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM1_14 - Hematom retroperitoneal secundar unei tumori maligne de glandă suprarenală (Mădălina Vitan)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fTcjyZaSfiYeFGHauZgSRpZxXeKqwWIE/view?usp=sharing')
                },
                // {
                //   title: 'PM1_15 - Suprarenalectomia prin abord laparoscopic – experiența Clinicii de Urologie din Oradea (B. Feciche)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM1_16 - Impactul imunoterapiei combinate în tratamentul carcinomului renal cu celule renale și tromboză concomitentă de venă cavă inferioară (A. Danilov)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gJOY33R9LDkewlp-yD68utudStZ0zmJ9/view?usp=sharing')
                },
              ]
            },
            {
              label: 'Sesiune postere 2 - Oncologie 2', 
              photos: [
                {
                  title: 'PM2_1 - Metastaze bilaterale la nivelul glandelor suprarenale în carcinomul renal unilateral. Tratament și complicații: prezentare de caz (F. Barbu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1g6tj9HQXfpFlCrYbbkLxLDxKGjKrmUot/view?usp=sharing')
                },
                {
                  title: 'PM2_2 - Tumoră renală parenchimatoasă cu aspect clinic de tumoră urotelială înaltă. Raport de caz.(Dinu T.P)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gM7DnXtJe9NSmZoAXU7BCMnGZcuAO1rV/view?usp=sharing')
                },
                // {
                //   title: 'PM2_3 - Tratamentul chirurgical al cancerului renal cu celule clare bilateral oligometastatic (S. Ianiotescu)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM2_4 - Experiența inițială în nefrectomia parțială (R. Popescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gZ23MEqx5shorZDp3eFUAIl9P2cGJOMR/view?usp=sharing')
                },
                {
                  title: 'PM2_5 - Sângerare tardivă apărută după nefrectomia parțială și manageriată prin embolizare selectivă arterială: Prezentare de caz (Alexandra Diana Savu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fkf7N3F8q6Kd0VP_o_us74UOISlm3cX8/view?usp=sharing')
                },
                // {
                //   title: 'PM2_6 - Pseudoanevrism arterial intrarenal – complicație rară după nefrectomia parțială: aspecte clinice și considerații terapeutice  (L. Bratu) ', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM2_7 - Abordarea chirurgicală a recidivei carcinomului renal papilar – prezentare de caz (E. Bolovan)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fEsY7WY2iMLHITMnlyb147Lv---B-w9f/view?usp=sharing')
                },
                {
                  title: 'PM2_8 - Dinamica progresiei în cancerul vezical non-musculoinvaziv – Evaluare la un an de la diagnostic (Carina-Alexandra Bandac)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1vQYd1q0-Bvm6dX3K1qywObnahi58nfO2/view?usp=sharing')
                },
                {
                  title: 'PM2_9 - Compararea rezultatelor perioperatorii și postoperatorii la pacienții cu derivații urinare tip ureterostomie cutanată directă respectiv Bricker în urma cistectomiei radicale (C.G. Radu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fDVQT3BWmCTaIDu53c7HoHAp8wK3_aae/view?usp=sharing')
                },
                {
                  title: 'PM2_10 - Conversia urostomiilor cutanate simple (N. Cairac)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fBodfSW_lft1puKNBtGI-UBJal3BZYIi/view?usp=sharing')
                },
                // {
                //   title: 'PM2_11 - Derivația urinară ileală în cistectomia radicală: un studiu retrospectiv pe o perioadă de 7 ani (M. Lazăr)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM2_12 - Cistectomie radicală laparoscopică cu derivație urinară intracorporeală – experiența noastră (M.M. Buzoianu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1Lx1qzptQF8Ea6hFYyHe17qu88wyGMRFf/view?usp=sharing')
                },
                {
                  title: 'PM2_13 - Brahiterapia HDR după excizia conservatoare în cancerul penian localizat: experiența pe termen lung a unei singure instituții (Kacsó)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fCxdvmEoUPZ6w9X5rPDybr3MRzW3QCJG/view?usp=sharing')
                },
                // {
                //   title: 'PM2_14 - Cistectomia radicală prin abord laparoscopic – experiența Clinicii de Urologie din Oradea. (B. Feciche)', 
                //   url: formatGooglePhoto('')
                // },
                // {
                //   title: 'PM2_15 - Prostatectomii radicale retropubice cu abord chirurgical laparoscopic – Experiența inițială a clinicii de urologie Brașov(M. Lazăr)', 
                //   url: formatGooglePhoto('')
                // },
                // {
                //   title: 'PM2_16 - Plasmocitom testicular bilateral – prezentare de caz (Angelica Ionescu)', 
                //   url: formatGooglePhoto('')
                // },
                // {
                //   title: 'PM2_17 - Nefrectomie radicală dreaptă și trombectomie venă cavă inferioară cu nefrectomie parțială dreaptă pentru carcinom renal cu celule clare și tromb VCI de nivel II - caz clinic (N. Cairac)', 
                //   url: formatGooglePhoto('')
                // },
              ]
            },
            {
              label: 'Sesiune postere 3 – Varia 1', 
              photos: [
                {
                  title: 'PM3_1 - Rezistența și sensibilitatea uropatogenilor în pacienții cu infecție acută COVID-19 (C. Mareș)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/17WQxxQFRZPJatE0hDU0eJkgDPat1ROHW/view?usp=sharing')
                },
                {
                  title: 'PM3_2 - Incidența infecției cu Clostridium difficile după intervențiile ȋn sfera urologică (A. Militaru)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fW1ugMzzvGtHhJpkbN1TdL7_uL0uj13_/view?usp=sharing')
                },
                {
                  title: 'PM3_3 - Managementul preoperator și postoperator al pacientilor cu infecții urinare multi-drug rezistente (MDR) C. Ene', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fCFC_J6IeLdLb1e920MdsBfuzWMj4qwt/view?usp=sharing')
                },
                {
                  title: 'PM3_4 - Tratamentul inițial al infecțiilor urinare recurente cu un autovaccin reduce numărul de episoade pe an (A. Ciudin)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fDgbZR4-RFY8ur7A323OyIwSIJ-pdHsK/view?usp=sharing')
                },
                {
                  title: 'PM3_5 - Antibioterapia pacienților cu gangrene Fournier: de la tratamentul empiric la antibiogramă (S. Gheorghincă)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1yFcrzNzN_xQa-jVWt0mxPuWDbpOtmvvC/view?usp=sharing')
                },
                {
                  title: 'PM3_6 - Coșmarul de după coșmar: Tratamentul consecințelor Gangrenei Fournier: Prezentare de caz (Țichil B.P.)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fBM6Fvpbi7W690TS6Bd9Nhx4Ma9HjYoG/view?usp=sharing')
                },
                // {
                //   title: 'PM3_7 - Prevalența și rezistența antibiotică a bacteriilor gram-negative în populația masculină (R.I. Popescu)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM3_8 - Prevalența și rezistența antibiotică a bacteriilor gram-negative în populația masculină (R.I. Popescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gY5GNBo9KPfDqgqOAcQa8rTn5qDwcNl0/view?usp=sharing')
                },
                {
                  title: 'PM3_9 - Spectrul și rezistența antimicrobiană a uropatogenilor în cadrul Spitalului Clinic “Prof. Dr. Th. Burghele” în perioada Covid (R.G. Vărzaru)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fOLP6kKqamcotUmUc8E3MDMR05lQRX7I/view?usp=sharing')
                },
                {
                  title: 'PM3_10 - Prezența febrei și a stării septice pe parcursul carantinei COVID-19: care este impactul lipsei de acces către servicii medicale pentru pacienții cu litiază urinară obstructivă complicată cu urosepsis? (Diţescu I.)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1erjOcxlL7_KkF9bO0_nnG-0Z3O1OocUC/view?usp=sharing')
                },
                {
                  title: 'PM3_11 - Managementul laparoscopic al rinichiului cu sindrom nefritic - experiența inițială  (C. Ene)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fCDqLdKJYbe8SmHv9RzRTW4jXZ-tj_7F/view?usp=sharing')
                },
                {
                  title: 'PM3_12 - Tratamentul laparoscopic în pionefroze, provocare sau alt nivel de experiență - Prezentare de caz (A. Laslo)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fYZOAiaAkkrG65FvacxAD_EoLFlONZBy/view?usp=sharing')
                },
                {
                  title: 'PM3_13 - Pieloplastia laparoscopică transperitoneală în cazuri recurente (R. Popescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gYag_imR432W_WVWfpg1pGSNrWYS11_Q/view?usp=sharing')
                },
                {
                  title: 'PM3_14 - Complicații uretero-vasculare tardive ale neoplasmelor de col uterin (P. Vișinescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fYWsGMZkFBlSHHYvGTQGQCxlFYVmbD6z/view?usp=sharing')
                },
                // {
                //   title: 'PM3_15 - Suprarenalectomia prin abord laparoscopic – experiența Clinicii de Urologie din Oradea (B. Feciche)', 
                //   url: formatGooglePhoto('')
                // },
              ]
            },
            {
              label: 'Sesiune postere 4 – Litiaza 1', 
              photos: [
                {
                  title: 'PM4_1 - Analiza complexă morfologică și spectroscopică a calculilor monobloc eliminați spontan (M. Ivănuță)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1YW24OoAoVWd-aF0lSC81k1_pk4ko7x6g/view?usp=sharing')
                },
                // {
                //   title: 'PM4_2  - Analiza compoziției chimice a calculilor urinari și caracterizarea clinică a pacienților tratați pentru litiază urinară într-un singur centru în București (T.M. Proca)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM4_3 - Deficitul de apă la pacienții obezi prefigurează recidiva litiazică? (M. Ivănuță)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1TlSqgpzZa8H8RuEUvENWac5DHe0ENB5h/view?usp=sharing')
                },
                {
                  title: 'PM4_4 - Hipotermia ca factor de risc pentru aritmii cardiace și hemoragie în timpul NLP-ului- case report (Bianca Stoica) ', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gGI8G3DfLrjalp3s2tuohF9NGn37Nvr4/view?usp=sharing')
                },
                {
                  title: 'PM4_5 - Managementul litiazei reno-ureterale - de la prezentarea în camera de gardă la tratamenul definitiv (A. Pleșuvescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fUZCRtkFh9imZ4F9fnq4RhXcw1jWRu4x/view?usp=sharing')
                },
                {
                  title: 'PM4_6 - Rata free-stone la pacienții care beneficiază de tratament cu citrați și piridoxină postoperator (A. Ene)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gQqCgVuL8uc2zTtFNO1tzwkvUaurz4hw/view?usp=sharing')
                },
                {
                  title: 'PM4_7 - Monitor izarea fragmentelor restante după NLP – Ce putem face? (A. Bojan)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1xqrW190Zie7LoKAok9TMLB8-4I0AXmSQ/view?usp=sharing')
                },
                {
                  title: 'PM4_8 - Litotriția extracorporeală vs ureteroscopia flexibilă pentru calculi renali sub 20 mm (R.N. Cartas)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fDuUuhWiO-SL3kVxleJnvhCP3BSbVeJH/view?usp=sharing')
                },
                {
                  title: 'PM4_9 - Ureteroscopie Flexibilă vs NLP pentru calculi renali (I. Nedelcu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fPSYSWpIITIY_Hdce1a7eWOBtQnnbIW0/view?usp=sharing')
                },
                // {
                //   title: 'PM4_10 - Ureteroscopie Flexibilă vs NLP pentru calculii peste 2 cm: Studiu Retrospectiv într-un singur centru (I. Nedelcu)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM4_11 - Ureteroscopia flexibilă versus nefrolitotomia percutanată în tratamentul litiazei renale de peste 2 cm (C. Cozma)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fwZnyGGV4F364Wnj2QCdYjl92tLY7vcE/view?usp=sharing')
                },
                {
                  title: 'PM4_12 - Prevenirea încrustării stentului JJ – Strategii terapeutice la îndemână (E. Coșovanu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1LEjofZSYtug2jDk4cn6NyLplvCreX2Xf/view?usp=sharing')
                },
                // {
                //   title: 'PM4_13 - Managementul calculilor urinari, prin tehnica Nefrolitholapaxiei Minim Invazive (MIP) ( A. Greluș)', 
                //   url: formatGooglePhoto('')
                // },
                // {
                //   title: 'PM4_14 - Litotriptor Spark-gap versus electromagnetic – Care este mai agresiv? (M. Ivănuță)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM4_15 - Nefrolitotomia percutanată în poziția Valdivia-Galdakao - experiența clinicii ”Prof. Dr. Th. Burghele” (R.G. Vărzaru )', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fOfBjT8fohq_iP8AnPLq5yoZm2yFGwPN/view?usp=sharing')
                },
                // {
                //   title: 'PM4_16 - Mini-Perc (MP) – tehnica chirurgicală, pas cu pas (M. Vârlan)', 
                //   url: formatGooglePhoto('')
                // },
              ]
            },
            {
              label: 'Sesiune postere 5 – Litiaza 2', 
              photos: [
                {
                  title: 'PM5_1 - Stenless app.: Aplicație mobilă pentru evidența stenturilor ureterale (L. Giurgiu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fYxkRLgrRwcScdEM6myFNlpJWrsbj3FZ/view?usp=sharing')
                },
                {
                  title: 'PM5_2 - Ureteroscopia flexibilă în abordul calculului coralliform (R. Popescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gYthdHUKUMTp7kLnY1tSqcLQpdvCmnmV/view?usp=sharing')
                },
                {
                  title: 'PM5_3 - Facilitated ureteroscopy using DJ stent implantation in ureterolithiasis: A retrospective study (Ronen Rub)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fIkyyqfqfIEjfhAYtZibu1Fzz6XDcgeX/view?usp=sharing')
                },
                {
                  title: 'PM5_4 - Ureteroscopia flexibilă retrograde (G. Mitroi)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gEbp8BDba5kp_bNciaOEyv6Xeb2izOKP/view?usp=sharing')
                },
                // {
                //   title: 'PM5_5 - Ureteroscoapele reutilizabile versus cele de unică folosință: O comparație elocventă (M. Bragaru)', 
                //   url: formatGooglePhoto('')
                // },
                // {
                //   title: 'PM5_6 - Ureteroscopia flexibilă fără expunere radiologică (M. Bragaru)', 
                //   url: formatGooglePhoto('')
                // },
                {
                  title: 'PM5_7 - Complicațiile ureteroscopiei flexibile și ale litotritiei cu laser Holmium în tratamentul litiazei renale (A.M. Punga)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/18GcM0u2nX9zgIuoXHGgvhuJq5lkbAqeH/view?usp=sharing')
                },
                {
                  title: 'PM5_8 - Prima experiență cu ureteroscopul flexibil de unică folosință HugeMed (V. Iordache)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gGFbtg3jLnuzn2wZI4ci4Qa-HrMgit2-/view?usp=sharing')
                },
                {
                  title: 'PM5_9 - Experiență românească după 4 ani cu ureteroscoapele flexibile de unică folosință de tip Pusen (V. Iordache)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gGI7ahrg40OU4X40r5ymVI5HdLhbfKtj/view?usp=sharing')
                },
                {
                  title: 'PM5_10 - Experiența inițială cu fibra laser Thulium versus Holmium:YAG in litotriția ureteroscopică (C. Rusu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fEu2_CKLCDdDbzV0aTN0hAlNaoJpj7dU/view?usp=sharing')
                },
                {
                  title: 'PM5_11 - Experiența cu ureteroscopul flexibil de unică utilizare Lithovue (V. Mirciulescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gR5tdyBBpJQulQdno3b8jJgey6iX4n1o/view?usp=sharing')
                },
                {
                  title: 'PM5_12 - Tehnica “no-touch” în ureteroscopia flexibilă single-use în managementul calculilor intrarenali (C. Mares)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1XAmmdXcQJIBcRqPiHTkz5z2gh5PYqPnb/view?usp=sharing')
                },
                {
                  title: 'PM5_13 - Prezentare de caz – Abord endoscopic în litiaza ureterală distală la pacient non-standard cu status post cistectomie și derivație urinară continentă (A. Griga)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fPJ8o40X5a4rDZLH72bhkLi3y_7PO46S/view?usp=sharing')
                },
                {
                  title: 'PM5_14 - Ureteroscopia semirigidă la pacienții cu calcul pielic unic (C. Petcu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fBHanNtNlA1hNXeBV6Nvon7CsPA885O4/view?usp=sharing')
                },
                {
                  title: 'PM5_15 - Ureterorenoscopia flexibilă anterogradă la pacienții cu derivație urinară tip Bricker (C. Petcu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1VqAJPlILRyDn_ZoF74p563Cq1KforTyk/view?usp=sharing')
                },
                // {
                //   title: 'PM5_16 - Ureteroscopia retrogradă flexibilă în rezolvarea patologiei litiazice renale – Experiență inițială (R. Rotaru)', 
                //   url: formatGooglePhoto('')
                // },
              ]
            },
            {
              label: 'Sesiune postere 6 – Varia 2', 
              photos: [
                {
                  title: 'PM6_1 - Comorbiditățile rinichiului unic – Cum le monitorizăm ? (I. Chiriac)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1Ba396qzx1HjID7J99SchX7jLac_A0Sk5/view?usp=sharing')
                },
                {
                  title: 'PM6_2 - Profilaxia sindromului sondei JJ – Monoterapie sau terapie combinată ? (Carina-Alexandra Bandac)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1W73DC3bbXJQgeRefvQ1hOiHkhTEkt_Y-/view?usp=sharing')
                },
                {
                  title: 'PM6_3 - Sindromul OHVIRA și ectopia ureterală cu deschidere în hemiuterul ipsilateral, diagnosticat la grupa de vârstă prepubertară: prezentare de caz și review de literatură (Teodora Telecan)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fEDXZSRL6W0mU867rIKxfVAC7AR4SAbR/view?usp=sharing')
                },
                {
                  title: 'PM6_4 - Prezentare de caz : Traumatism pelvin secundar unui fier beton de 42 de cm (Ene A.)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gRIqigqkR-TMjSe02qXjRHAArPSTiIIi/view?usp=sharing')
                },
                {
                  title: 'PM6_5 - Automutilare uretro-vezicală  (A. Zamfira) ', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fDf7BOZB7zfURgYJ3DVKW-aBkLMnNn6K/view?usp=sharing')
                },
                {
                  title: 'PM6_6 - Instruirea abilităților de ultrasonografie a aparatului urinar pentru studenți utilizând simularea medical (G. Mandas)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gJxTcyh3WmkyEeAuNShK_i4FvgMNCR2F/view?usp=sharing')
                },
                {
                  title: 'PM6_7 - Embolie venoasă consecutiv unei rezecții transuretrale pentru hipertrofia benignă de prostată. Prezentare de caz (V.A. Abramescu)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fUi8dCn6DA7b-je1dy_FfA_kyBl6ZHEq/view?usp=sharing')
                },
                {
                  title: 'PM6_8 - Aspecte rar întâlnite ale patologiei urologice de urgență (A. Amza)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1m9N0-aqqR1e284FzY5bNnmHSPnP4fbwv/view?usp=sharing')
                },
                {
                  title: 'PM6_9 - Fistula sigmoido-neovezicală (colo-enterică) (F. Alexe)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1gItty4j2mMhtfSyOhVl9C78rOcRwut-F/view?usp=sharing')
                },
                {
                  title: 'PM6_10 - Evaluarea disfuncției erectile la pacientul transplantat – Care sunt factorii de risc? (Adelina Miron)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/19t4OkV7Fc4MkI5nVtx16CJ-oYDGe8yKU/view?usp=sharing')
                },
                {
                  title: 'PM6_11 - Eficacitatea monoterapiei (Tadalafil 5 mg zilnic) versus terapia combinată (Tadalafil 5 mg asociat cu Vitaros cremă la nevoie) în tratamentul de reabilitare peniană (C. Belinski)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fF8_LBPnrEKDqXwrNACznK01ffmATW9x/view?usp=sharing')
                },
                {
                  title: 'PM6_12 - Impact of seasonal variations on incidence and laterality of testicular torsion (Ronen Rub)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fIS-oRpkJYwK1_0Z9mDaIO5ZDCNqxwTP/view?usp=sharing')
                },
                {
                  title: 'PM6_13 - Short-term intracavernous self-injection treatment of psychogenic erectile dysfunction secondary to sexual performance anxiety in unconsummated marriages (Ronen Rub)', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fJCUX6YOXKenUKKJC14O_BJFLia2K8UZ/view?usp=sharing')
                },
                {
                  title: 'PM6_14 - Managementul chirurgical al leziunilor peniene induse de autoinjectarea de canamicină (M. Firoiu )', 
                  url: formatGooglePhoto('https://drive.google.com/file/d/1fV8uhfsWVcURWBxsQ6mmaS5MI-nczaUj/view?usp=sharing')
                },
                // {
                //   title: 'PM6_15 - Rezultate după rezecția transuretrală a prostatei la pacienții cu retenție urinară, hiperplazie benignă de prostată și detrusor hipocontractil (Veronica Ghirca)', 
                //   url: formatGooglePhoto('')
                // },
              ]
            }
        ]
        
        return galleries
    }
    setSearchParams(nameE, value) {
        if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${nameE}=${value}`;
            window.history.pushState({path:newurl},'',newurl);
        }

        this.setState({activeGallery: value})
    }

    setSearchParamsS(nameE, value) {
        if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?gallery=${this.state.activeGallery}&${nameE}=${value}`;
            window.history.pushState({path:newurl},'',newurl);
        }

        this.setState({activePhoto: value})
    }

    componentDidMount() {
        let pass = prompt('', '')

        if(pass !== 'Romuro@2022') {
            window.close()
        } else {
            this.setState({isAuth: true})
        }
    }
    render() {
        return this.state.isAuth && <><div className='gallery-box' style={{
            width: '100%',
            height: 'calc(100vh - 38px)',
            backgroundColor: '#fff',
            overflow: 'auto'
        }}>
          {this.state.tab !== "" && <button style={{
                    position: 'fixed',
                    right: '28px',
                    top: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({tab: ""})
                }}>X</button>}
                {this.state.tab === "alb" && this.state.activeVideo !== 0 && <button style={{
                    position: 'fixed',
                    right: '28px',
                    top: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({activeVideo: 0})
                }}>X</button>}
            {this.state.tab === "" && <div className='gallery-list' style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>
                <button style={{
                    // height: '50px',
                    width: '200px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    margin: '10px',
                    padding: '15px 0',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({tab: "alb"})
                }}>VIDEO</button>
                <button style={{
                    // height: '50px',
                    width: '200px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    margin: '10px',
                    padding: '15px 0',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({tab: "gal"})
                }}>E-POSTERE</button>
            </div>}

            {this.state.tab === "alb" && this.state.activeVideo === 0 && <div className='gallery-list' style={{
                display: 'flex',
                // alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                flexWrap: "wrap"
                // height: '100%'
            }}>
              
                {this.getAlbum().map((gallery, index) => <button key={index} style={{
                    // height: '50px',
                    width: '200px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    margin: '10px',
                    padding: '15px 0',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setState({activeVideo: index+1})
                }}>{gallery.title}</button>)}
            </div>}

            {this.state.tab === "alb" && this.state.activeVideo !== 0 && <div style={{width: "100%", height: "calc(100vh - 46px)"}}>
                <iframe width="100%" height="100%" src={this.getAlbum()[this.state.activeVideo-1].url} />
              </div>}

            {this.state.tab === "gal" && this.state.activeGallery === 0 && this.state.activePhoto === 0 && <div className='gallery-list' style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%'
            }}>
              
                {this.getGallery().map((gallery, index) => <button key={index} style={{
                    // height: '50px',
                    width: '200px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    margin: '10px',
                    padding: '15px 0',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setSearchParams('gallery', index+1)
                }}>{gallery.label}</button>)}
            </div>}
            {this.state.tab === "gal" && this.state.activeGallery !== 0 && this.state.activePhoto === 0 && <div className='gallery-photos' style={{
                height: 'max-contnet',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                {this.getGallery()[this.state.activeGallery-1].photos.map((photo, index) => <div onClick={() => {
                this.setSearchParamsS('photo', index+1)
                this.setState({activePhoto: index+1})
            }} style={{
                        margin: '10px 50px',
                        width: '20%',
                    }}>
                    <img style={{
                        margin: '0 0 5px 0',
                        width: '100%',
                    }} key={photo.url} src={photo.url} />
                    <p style={{maxWidth: '100%', fontWeight: 'bold', fontFamily: 'Arial'}}>{
                        photo.title
                            .replace('_', ' ')
                            .replace('_', ' ')
                            .replace('_', ' ')
                            .replace('_', ' ')
                            .replace('_', ' ')
                            .replace('_', ' ')
                            .replace('_', ' ')
                    }</p>
                </div>)} <button style={{
                    position: 'fixed',
                    right: '28px',
                    top: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setSearchParams('gallery', 0)
                }}>X</button>
            </div>}

            {this.state.activeGallery !== 0 && this.state.activePhoto !== 0 && <div className='gallery-photo' style={{
                height: 'calc(100vh - 38px)',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
 <img style={{width: '95%'}} src={this.getGallery()[this.state.activeGallery-1].photos[this.state.activePhoto-1].url} />
                <button style={{
                    position: 'fixed',
                    right: '28px',
                    top: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setSearchParamsS('photo', 0)
                }}>X</button>

                {this.state.activePhoto > 1 && <button style={{
                    position: 'fixed',
                    left: '28px',
                    bottom: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setSearchParamsS('photo', this.state.activePhoto-1)
                }}>{'<'}</button>}

                {this.state.activePhoto < this.getGallery()[this.state.activeGallery-1].photos.length && <button style={{
                    position: 'fixed',
                    right: '28px',
                    bottom: '10px',
                    width: '50px',
                    height: '50px',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    border: 'none',
                    color: '#fff',
                    backgroundColor: '#41b9ee',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }} onClick={() => {
                    this.setSearchParamsS('photo', this.state.activePhoto+1)
                }}>{'>'}</button>}
            </div>}

                        
        </div>
        <div style={{textAlign: 'center', fontFamily: 'Arial', padding: '10px'}}>Acest website contine informatii care se adreseaza numai cadrelor medicale</div>
    </>
}
}


export default Pdf
