import { combineReducers } from 'redux'

import user from './user'
import chat from './chat'
import admin from './admin'
import poll from './poll'
import app from './app'

export default combineReducers({
    user,
    chat,
    admin,
    poll,
    app
})