import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import { CookiesProvider } from 'react-cookie'
import {
    BrowserRouter as Router,
} from "react-router-dom"

// Redux
import store from './Redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
);