import { 
    NEW_POLL,
    REMOVE_POLL,
    GET_POLLS
} from '../constants'

const INITIAL_STATE = {
    polls: [],
    isGetted: false
}

const poll = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_POLLS: {
            return { ...state, polls: action.payload , isGetted: true}
        }
        case NEW_POLL: {
            return { ...state, polls: [ ...state.polls, action.payload ] }
        }
        case REMOVE_POLL: {
            return { ...state, polls: [ ...state.polls.filter(poll => {        
                return poll._id != action.payload
            })] }
        }
        default:
            return state
    }
}

export default poll