import { 
    GET_APP
} from '../constants'

const INITIAL_STATE = {
    isGetted: false
}

const app = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_APP: {
            return { ...state, ...action.payload, isGetted: true }
        }
        default:
            return state
    }
}

export default app