// User
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

// Chat
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const GET_MESSAGES = 'GET_MESSAGES'

// Poll
export const NEW_POLL = 'NEW_POLL'
export const REMOVE_POLL = 'REMOVE_POLL'
export const GET_POLLS = 'GET_POLLS'

// Admin
export const ADMIN_GET_CHATS = 'ADMIN_GET_CHATS'
export const ADMIN_GET_CHAT = 'ADMIN_GET_CHAT'
export const ADMIN_ADD_MESSAGE = 'ADMIN_ADD_MESSAGE'
export const GET_USERS = 'GET_USERS'
export const REMOVE_USER = 'REMOVE_USER'
export const ADD_USER = 'ADD_USER'
export const ADD_CHAT = 'ADD_CHAT'
export const ADMIN_READ = 'ADMIN_READ'
export const ADMIN_GET_POLLS = 'ADMIN_GET_POLLS'
export const ADMIN_ADD_POLL = 'ADMIN_ADD_POLL'
export const ADMIN_REMOVE_POLL = 'ADMIN_REMOVE_POLL'
export const ADMIN_ADD_ANSWER = 'ADMIN_ADD_ANSWER'
export const CHANGE_POLL_STATUS = 'CHANGE_POLL_STATUS'

// Timer
export const SET_TIMER = 'SET_TIMER'

// App
export const GET_APP = 'GET_APP'